<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary pb-0 mt-2">
      {{ $t('pages.campaign.title') }}
    </h1>
    <h5
        v-if="campaign !== null"
        class="text-ellipsis"
    >
      {{ campaign.name }}
    </h5>

    <div class="mt-3 mb-3">
      <template v-if="loader || campaign === null">
        <div class="d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
              variant="primary"
              style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>

      <template v-else>
        <div
            v-if="stats !== null"
            class="d-flex mb-50"
        >
          <div>
            <h4>{{ stats.emoji }}</h4>
          </div>
          <div class="ml-50">
            <h5
                class="mb-0"
            >
              {{ stats.name }}
            </h5>
            <h7 class="text-black-50">{{ startDisplay }} - {{ endDisplay }}</h7>
          </div>
        </div>

        <template v-if="error">
          <div
              class="mb-5 text-muted text-center d-flex flex-column px-3 animate__animated animate__fadeIn animate__fast"
          >
            <h1 class="mt-3 mb-2">
              <i class="fa-regular fa-cloud-exclamation" />
            </h1>
            {{ $t('pages.campaign.one-crop.loading-error') }}

            <div>
              <TouchButton
                size="xs"
                class="mt-2"
                @action="()=>{
                  return loadData(true)
                }"
              >
                {{ $t('therms.retry') }}
              </TouchButton>
            </div>
          </div>
        </template>
        <template v-else-if="dataLoader">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
                variant="primary"
                small
                style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else-if="!dataLoader">
          <div class="mt-2 animate__animated animate__fadeInUp">
            <b-card
                class="mb-1"
            >
              <b-row>
                <b-col
                    class="d-flex justify-content-between align-items-center"
                >
                  <b-card-text class="mr-75 mb-0">
                    {{ $t('therms.hide-inactive-harvester')}}
                  </b-card-text>
                  <b-form-checkbox
                      v-model="onlyStaffMemberActive"
                      :disabled="displaySearchBar"
                      class="custom-control-secondary mr-0"
                      style="position: relative; right: -6px"
                      name="check-button"
                      switch
                      inline
                  />
                </b-col>
              </b-row>
            </b-card>

            <b-card
                class="d-none d-md-block mb-1"
            >
              <b-row>
                <b-col>
                  <b-card-text class="mb-50">
                    {{ $t('therms.sort-harvester-by')}} :
                  </b-card-text>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <touch-button-group class="w-100 d-flex">
                    <touch-button
                        outline
                        class="flex-fill"
                        :class="{'active' : sort === sortPerWeight}"
                        ingroup
                        size="sm"
                        @action="sort = sortPerWeight"
                        style="width: 33%; padding: 6px 20px;"
                    >
                      <span>{{ capitalizeFirstLetter($t('units.kilogram').toString()) }}</span>
                    </touch-button>
                    <touch-button
                        outline
                        class="flex-fill"
                        :class="{'active' : sort === sortPerQuantity}"
                        ingroup
                        size="sm"
                        @action="sort = sortPerQuantity"
                        style="width: 33%; padding: 6px 20px;"
                    >
                      <span>{{ capitalizeFirstLetter($t('units.quantity').toString()) }}</span>
                    </touch-button>
                    <touch-button
                        outline
                        class="flex-fill"
                        :class="{'active' : sort === sortPerCode}"
                        ingroup
                        size="sm"
                        @action="sort = sortPerCode"
                        style="width: 33%; padding: 6px 20px;"
                    >
                      <span>{{ capitalizeFirstLetter($t('therms.number').toString()) }}</span>
                    </touch-button>
                  </touch-button-group>
                </b-col>
              </b-row>
            </b-card>

            <b-row>
              <div class="col-12 col-lg-5 col-xl-4">
                <PickingRepartitionCamembertCard
                    class="mb-3 animate__animated animate__fadeIn"
                    :title="$t('therms.harvester-distribution').toString()"
                    :items="repartition"
                    :only-staff-member-active="onlyStaffMemberActive"
                    :is-item-options="true"
                />
              </div>

              <div class="col-12 col-lg-7 col-xl-8">
                <touch-button-group v-if="!isTablet" class="w-100 mb-2 d-flex">
                  <touch-button
                      class="flex-fill"
                      :class="{'active' : mode === mode_simplified}"
                      ingroup
                      outline
                      thinkiness
                      size="sm"
                      @action="mode = mode_simplified"
                      style="width: 33%"
                  >
                    <span class="wrap">{{ $t('therms.simplified-view')}}</span>
                  </touch-button>
                  <touch-button
                      class="flex-fill"
                      :class="{'active' : mode === mode_graphical}"
                      ingroup
                      outline
                      thinkiness
                      size="sm"
                      @action="mode = mode_graphical"
                      style="width: 33%"
                  >
                    <span class="wrap">{{ $t('therms.graphical-view')}}</span>
                  </touch-button>
                  <touch-button
                      class="flex-fill"
                      :class="{'active' : mode === mode_progressive}"
                      ingroup
                      outline
                      thinkiness
                      size="sm"
                      @action="mode = mode_progressive"
                      style="width: 33%"
                  >
                    <span class="wrap">{{ $t('therms.progressive-view')}}</span>
                  </touch-button>
                </touch-button-group>

                <transition name="scale-in">
                  <b-card
                      v-if="!isTablet && (mode === mode_simplified || mode === mode_graphical)"
                      class="d-block d-md-none mb-1"
                  >
                    <b-row>
                      <b-col>
                        <b-card-text class="mb-50">
                          {{ $t('therms.sort-harvester-by')}} :
                        </b-card-text>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <touch-button-group class="w-100 d-flex">
                          <touch-button
                              outline
                              class="flex-fill"
                              :class="{'active' : sort === sortPerWeight}"
                              ingroup
                              size="sm"
                              @action="sort = sortPerWeight"
                              style="width: 33%"
                          >
                            <span>{{ capitalizeFirstLetter($t('units.kilogram').toString()) }}</span>
                          </touch-button>
                          <touch-button
                              outline
                              class="flex-fill"
                              :class="{'active' : sort === sortPerQuantity}"
                              ingroup
                              size="sm"
                              @action="sort = sortPerQuantity"
                              style="width: 33%"
                          >
                            <span>{{ capitalizeFirstLetter($t('units.quantity').toString()) }}</span>
                          </touch-button>
                          <touch-button
                              outline
                              class="flex-fill"
                              :class="{'active' : sort === sortPerCode}"
                              ingroup
                              size="sm"
                              @action="sort = sortPerCode"
                              style="width: 33%"
                          >
                            <span>{{ capitalizeFirstLetter($t('therms.number').toString()) }}</span>
                          </touch-button>
                        </touch-button-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </transition>

                <PickingCardResume
                    v-if="((!isTablet && mode === mode_simplified) || isTablet) && stats !== null"
                    :id="statsSimplifiedData.id"
                    :key="statsSimplifiedData.id"
                    class="animate__animated animate__fadeIn"
                    :emoji="statsSimplifiedData.emoji"
                    :name="$t('therms.harvester-stats').toString()"
                    :total-weight="statsSimplifiedData.total_weight"
                    :quantity="statsSimplifiedData.quantity"
                    :items="statsSimplifiedData.items"
                    :variant="'primary'"
                    :filter-active="onlyStaffMemberActive"
                    :sort-by="sort"
                />

                <PickingBarChartCardResume
                    v-if="(!isTablet && mode === mode_graphical) && stats !== null"
                    :id="statsGraphicalData.id"
                    :key="statsGraphicalData.id"
                    class="animate__animated animate__fadeIn"
                    :name="$t('therms.harvester-analyze').toString()"
                    :total-weight="statsGraphicalData.total_weight"
                    :quantity="statsGraphicalData.quantity"
                    :items="statsGraphicalData.items"
                    :variant="'primary'"
                    :color="chartColors.primaryColorShade"
                    :filter-active="onlyStaffMemberActive"
                    :unit="$t('units.kg').toString()"
                    :sort-by="sort"
                />

                <PickingProgressionCard
                    v-if="(!isTablet && mode === mode_progressive) && progressionsChartData !== null"
                    :legend="true"
                    class="animate__animated animate__fadeIn"
                    :title="$t('therms.harvester-progression').toString()"
                    :variant="'primary'"
                    :total-weight="statsGraphicalData.total_weight"
                    :quantity="statsGraphicalData.quantity"
                    :categories="progressionsChartData.categories"
                    :colors="progressionsChartData.colors"
                    :series="progressionsChartData.series"
                    :series-options="progressionsChartData.seriesOptions"
                    :defaultUnit="progressionsChartData.units[0].key"
                    :units="progressionsChartData.units"
                />
              </div>


              <div class="col-12">
                <PickingBarChartCardResume
                    v-if="isTablet && stats !== null"
                    :id="statsGraphicalData.id"
                    :key="statsGraphicalData.id"
                    class="animate__animated animate__fadeIn"
                    :name="$t('therms.harvester-analyze').toString()"
                    :total-weight="statsGraphicalData.total_weight"
                    :quantity="statsGraphicalData.quantity"
                    :items="statsGraphicalData.items"
                    :variant="'primary'"
                    :color="chartColors.primaryColorShade"
                    :filter-active="onlyStaffMemberActive"
                    :unit="$t('units.kg').toString()"
                    :sort-by="sort"
                />
              </div>
              <div class="col-12">
                <PickingProgressionCard
                    v-if="isTablet && progressionsChartData !== null"
                    :legend="true"
                    class="animate__animated animate__fadeIn"
                    :title="$t('therms.harvester-progression').toString()"
                    :variant="'primary'"
                    :total-weight="statsGraphicalData.total_weight"
                    :quantity="statsGraphicalData.quantity"
                    :categories="progressionsChartData.categories"
                    :colors="progressionsChartData.colors"
                    :series="progressionsChartData.series"
                    :series-options="progressionsChartData.seriesOptions"
                    :defaultUnit="progressionsChartData.units[0].key"
                    :units="progressionsChartData.units"
                />
              </div>
            </b-row>

            <b-row>
              <b-col>
                <b-card
                    class="mt-1 mb-0"
                >
                  <b-row>
                    <b-col
                        class="d-flex justify-content-between align-items-center"
                    >
                      <b-card-text class="mr-75 mb-0">
                        {{ $t('therms.display-only-harvest-period')}}
                      </b-card-text>
                      <b-form-checkbox
                          v-model="onlyRealHarvestUnit"
                          class="custom-control-secondary mr-0"
                          style="position: relative; right: -6px"
                          name="check-button"
                          switch
                          inline
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <PickingsUnitInCampaign
                  class="col-12 col-lg-4"
                  v-if="unitsPerYear.length>=1"
                  :title="$t('therms.by-years').toString()"
                  :items="unitsPerYear"
                  :go-to="goToYear"
              />
              <PickingsUnitInCampaign
                  class="col-12 col-lg-4"
                  v-if="unitsPerMonths.length>=1"
                  :title="$t('therms.by-months').toString()"
                  :items="unitsPerMonths"
                  :go-to="goToMonth"
              />
              <PickingsUnitInCampaign
                  class="col-12 col-lg-4"
                  v-if="unitsPerDay.length>=1"
                  :title="$t('therms.by-days').toString()"
                  :items="unitsPerDay"
                  :go-to="goToDay"
              />
            </b-row>
          </div>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import chartColors from '@core/mixins/charts/colors'
import {
  BCard, BCardText, BCol, BFormCheckbox, BRow, BSpinner,
} from 'bootstrap-vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'
// eslint-disable-next-line import/no-cycle
import { NAME_RESUME_CAMPAIGNS, NAME_RESUME_ONE_CAMPAIGN_CROP_CUSTOM_SCOPE } from '@/router/routes/campaigns'
import ProductionBarChartCard from '@/views/components/ProductionBarChartCard.vue'
import PickingCardResume from '@/views/components/PickingCardResume.vue'
import PickingProgressionCard from '@/views/components/PickingProgressionCard.vue'
import PickingsUnitInCampaign from '@/views/components/PickingsUnitInCampaign.vue'
import PickingRepartitionCamembertCard from '@/views/components/PickingRepartitionCamembertCard.vue'
import PickingBarChartCardResume from '@/views/components/PickingBarChartCardResume.vue'
import { sortPerCode, sortPerQuantity, sortPerWeight } from '@/declations/PickingResumeSort'
import { collect } from 'collect.js'
import { NAME_HISTORY_DATE } from '@/router/routes/history'

export default {
  components: {
    NavButtonBar,
    NavButton,
    BSpinner,
    PickingProgressionCard,
    ProductionBarChartCard,
    TouchButtonGroup,
    TouchButton,
    PickingRepartitionCamembertCard,
    PickingCardResume,
    PickingsUnitInCampaign,
    PickingBarChartCardResume,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BCardText,
  },
  data() {
    return {
      // UI
      loader: false,
      dataLoader: false,
      error: false,
      sort: sortPerWeight,
      mode: 'simplified',
      onlyStaffMemberActive: true,
      onlyRealHarvestUnit: true,
      displayFilters: false,
      displaySearchBar: false,

      // data
      campaign: null,
      stats: null,
      progressions: null,
      start: null,
      end: null,
    }
  },
  watch: {
    '$route.params': {
      handler: async function () {
        setTimeout(async () => {
          this.setStartAndEndDateRequired()
          await this.loadData()
        }, 500)
      },
      deep: true,
      immediate: false
    }
  },
  computed: {
    /**
     * @returns {String}
     */
    startDisplay() {
      return this.$moment(this.start)
          .format('L')
    },
    /**
     * @returns {String}
     */
    endDisplay() {
      return this.$moment(this.end)
          .format('L')
    },
    /**
     * @returns {boolean}
     */
    isTablet() {
      return window.isTablet()
    },
    /**
     * @returns {string}
     */
    sortPerQuantity() {
      return sortPerQuantity
    },
    /**
     * @returns {string}
     */
    sortPerWeight() {
      return sortPerWeight
    },
    /**
     * @returns {string}
     */
    sortPerCode() {
      return sortPerCode
    },
    /**
     * @returns {string}
     */
    mode_simplified() {
      return 'simplified'
    },
    /**
     * @returns {string}
     */
    mode_graphical() {
      return 'graphical'
    },
    /**
     * @returns {string}
     */
    mode_progressive() {
      return 'progressive'
    },
    /**
     * @returns {{quantity, name: string, id: number, items: (*|*[]), total_weight}}
     */
    statsSimplifiedData() {
      const items = this.stats.harvest.staff_members.map(sm => ({
        language: sm.language,
        name: `${sm.firstname.trim()} ${sm.lastname.trim()}`.trim(),
        code: sm.code,
        total_weight: sm.weight,
        quantity: sm.quantity,
      }))

      return {
        id: 1,
        total_weight: this.stats.harvest.weight,
        quantity: this.stats.harvest.quantities,
        items: items ?? [],
      }
    },
    /**
     * @returns {{quantity, id: number, items: (*|*[]), total_weight}}
     */
    statsGraphicalData() {
      const items = this.stats.harvest.staff_members.map(sm => ({
        language: sm.language,
        name: `${sm.firstname.trim()} ${sm.lastname.trim()}`.trim(),
        code: sm.code,
        total_weight: sm.weight,
        quantity: sm.quantity,
      }))

      return {
        id: 1,
        total_weight: this.stats.harvest.weight,
        quantity: this.stats.harvest.quantities,
        items: items ?? [],
      }
    },
    /**
     * @returns {[{name: string, id: string, items: *},{name: string, id: string, items: *}]}
     */
    repartition() {
      const totalQt = this.stats.harvest.quantities
      const totalWeight = this.stats.harvest.weight

      return [
        {
          id: 'kg',
          name: this.$t('units.kg'),
          color: this.stats.color,
          items: this.stats.harvest.staff_members.map(sm => ({
            code: sm.code,
            language: sm.language,
            label: `${sm.firstname.trim()} ${sm.lastname.trim()}`.trim(),
            // eslint-disable-next-line no-mixed-operators
            percent: Number((parseFloat(sm.weight) * 100 / parseFloat(totalWeight)).toFixed(2)),
          })),
        },
        {
          id: 'colis',
          name: this.$t('units.package'),
          color: this.stats.color,
          items: this.stats.harvest.staff_members.map(sm => ({
            code: sm.code,
            language: sm.language,
            label: `${sm.firstname.trim()} ${sm.lastname.trim()}`.trim(),
            // eslint-disable-next-line no-mixed-operators
            percent: Number((parseFloat(sm.quantity) * 100 / parseFloat(totalQt)).toFixed(2)),
          })),
        },
      ]
    },
    /**
     * @returns {{series: [{data: *}], categories: *, colors: *[]}}
     */
    progressionsChartData() {
      let data = (this.progressions?.staff_members.map(sm => ({
        name: `${sm.firstname.trim()} ${sm.lastname.trim()}`.trim(),
        code: sm.code,
        language: sm.language,
        weight: sm.harvest.all.map(e => e.weight),
        quantity: sm.harvest.all.map(e => e.quantity)
      }))
          .filter(e => {
            return (this.onlyStaffMemberActive ? e.weight.reduce((a, b) => a + b, 0) >= 1 || e.quantity.reduce((a, b) => a + b, 0) >= 1 : true)
          })) ?? {}

      return {
        categories: this.progressions?.staff_members[0]?.harvest?.all.map(e => {
          let d = (e.unit + '').split('-')
          switch (d.length) {
            case 1 :
              d.push('01')
              d.push('01')
              break
            case 2 :
              d.push('01')
              break
          }

          switch (this.campaignUnit) {
            case 'DAY':
              return this.$moment(d.join('-'))
                  .format('L')
            case 'MONTH':
              return this.$moment(d.join('-'))
                  .format('MMM YYYY')
            case 'YEAR':
              return this.$moment(d.join('-'))
                  .format('YYYY')
            default:
              return this.$moment(d.join('-'))
                  .calendar()
          }
        }) ?? [],
        series: data,
        seriesOptions: {
          lineStyle: {
            width: this.progressions?.staff_members.length > 5 ? 2 : 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg')
          },
          {
            key: 'quantity',
            name: this.$t('units.package')
          }
        ],
        colors: data.map(() => window.randomColor()),
      }
    },
    /**
     * @returns {{infoColorShade: string, lineChartPrimary: string, area: {series3: string, series2: string, series1: string}, blueColor: string, grid_line_color: string, primaryColorShade: string, warningLightColor: string, greyColor: string, blueLightColor: string, tooltipShadow: string, column: {bg: string, series2: string, series1: string}, labelColor: string, warningColorShade: string, greyLightColor: string, success: {shade_200: string, shade_100: string}, yellowColor: string, donut: {series4: string, series3: string, series5: string, series2: string, series1: string}, successColorShade: string, lineChartDanger: string}}
     */
    chartColors() {
      return chartColors
    },
    /**
     * @returns {string}
     */
    campaignUnit() {
      let diffInMonth = this.$moment(this.start)
          .diff(this.$moment(this.end), 'months', true)

      if (diffInMonth < 0) {
        diffInMonth *= (-1)
      }

      if (diffInMonth >= 15) return 'YEAR'
      if (diffInMonth >= 2) return 'MONTH'
      return 'DAY'
    },
    unitsPerDay() {
      if (this.campaignUnit !== 'DAY' && this.campaignUnit !== 'MONTH' && this.campaignUnit !== 'YEAR') {
        return []
      }

      let daysOfCampaign = Array.from(this.$moment.range(this.$moment(this.start), this.$moment(this.end))
          .by('day'))

      let daysOfRealCampaign = this.campaign?.units.map((unit) => {
        let u = (unit + '').split('-')
        while (u.length < 3) {
          u.push('01')
        }

        return this.$moment(u.join('-'))
            .format('YYYYMMDD')
      })

      return [
        ...(this.onlyRealHarvestUnit ? daysOfCampaign.filter(e => {
          return daysOfRealCampaign.indexOf(e.format('YYYYMMDD')) > -1
        }) : daysOfCampaign)
      ].filter((value, index, self) => {
        return self.indexOf(value) === index
      })
          .sort((unitA, unitB) => unitA.format('YYYYMMDD') - unitB.format('YYYYMMDD'))
          .map((unit) => {
            return {
              data: unit.format('YYYY-MM-DD'),
              name: unit.format('L')
            }
          }) ?? []
    },
    unitsPerMonths() {
      if (this.campaignUnit !== 'MONTH' && this.campaignUnit !== 'YEAR') {
        return []
      }
      let monthsOfCampaign = Array.from(this.$moment.range(this.$moment(this.start), this.$moment(this.end))
          .by('months'))
          .map(u => u.startOf('month'))

      let monthsOfRealCampaign = this.campaign?.units.map((unit) => {
        let u = (unit + '').split('-')
        while (u.length < 3) {
          u.push('01')
        }

        return this.$moment(u.join('-'))
            .format('YYYYMM')
      })

      return collect([
        ...(this.onlyRealHarvestUnit ? monthsOfCampaign.filter(e => {
          return monthsOfRealCampaign.indexOf(e.format('YYYYMM')) > -1
        }) : monthsOfCampaign)
          ]
              .sort((unitA, unitB) => unitA.format('YYYYMMDD') - unitB.format('YYYYMMDD'))
              .map((unit) => {
                return {
                  data: unit.format('YYYY-MM'),
                  name: unit.format('MMM YYYY'),
                  start: unit.startOf('month')
                      .format('YYYY-MM-DD HH:mm:ss'),
                  end: unit.endOf('month')
                      .format('YYYY-MM-DD HH:mm:ss')
                }
              })
      )
          .keyBy('data')
          .toArray() ?? []
    },
    unitsPerYear() {
      if (this.campaignUnit !== 'YEAR') {
        return []
      }
      let yearsOfCampaign = Array.from(this.$moment.range(this.$moment(this.start), this.$moment(this.end))
          .by('years'))
          .map(u => u.startOf('year'))

      let yearsOfRealCampaign = this.campaign?.units.map((unit) => {
        let u = (unit + '').split('-')
        while (u.length < 3) {
          u.push('01')
        }

        return this.$moment(u.join('-'))
            .format('YYYY')
      })

      return collect(
          ...(this.onlyRealHarvestUnit ? yearsOfCampaign.filter(e => {
            return yearsOfRealCampaign.indexOf(e.format('YYYY')) > -1
          }) : yearsOfCampaign)
              .sort((unitA, unitB) => unitA.format('YYYYMMDD') - unitB.format('YYYYMMDD'))
              .map((unit) => {
                return {
                  name: unit.format('YYYY'),
                  data: unit.format('YYYY'),
                  start: unit.startOf('year')
                      .format('YYYY-MM-DD HH:mm:ss'),
                  end: unit.endOf('year')
                      .format('YYYY-MM-DD HH:mm:ss')
                }
              }))
          .keyBy('data')
          .toArray() ?? []
    }
  },
  /**
   * @returns {Promise<void>}
   */
  async mounted() {
    try {
      if (typeof this.$router.currentRoute.params.start === 'undefined' || this.$router.currentRoute.params.start === null) {
        throw new Error('Cannot find start datetime')
      }

      if (typeof this.$router.currentRoute.params.end === 'undefined' || this.$router.currentRoute.params.end === null) {
        throw new Error('Cannot find end datetime')
      }

      if (typeof this.$router.currentRoute.params.crop_id === 'undefined' || this.$router.currentRoute.params.crop_id === null) {
        throw new Error('Cannot find crop id')
      }

      this.campaign = await (this.$store.dispatch('campaigns/find', this.$router.currentRoute.params.campaign_id))
      this.setStartAndEndDateRequired()
    } catch (err) {
      await this.$router.replace({ name: NAME_RESUME_CAMPAIGNS })
      throw err
    }

    await this.loadData()
  },
  methods: {
    /**
     * @param {string} string
     * @return {string}
     */
    capitalizeFirstLetter(string){
      return window.capitalizeFirstLetter(string);
    },
    /**
     * @returns {boolean}
     */
    isMonthView() {
      return this.$router.currentRoute.name === NAME_RESUME_ONE_CAMPAIGN_CROP_MONTH
    },
    setStartAndEndDateRequired() {
      this.start = decodeURI(this.$router.currentRoute.params.start)
      this.end = decodeURI(this.$router.currentRoute.params.end)

      this.start = (this.$moment(this.start)
          .isBefore(this.$moment(this.campaign.start)) ? this.campaign.start : this.start)
      this.end = (this.$moment(this.end)
          .isAfter(this.$moment(this.campaign.end)) ? this.campaign.end : this.end)
    },
    /**
     * @returns {Promise<void>}
     */
    async loadData() {
      try {
        if (this.loader || this.campaign === null) return

        this.error = false
        this.dataLoader = true

        this.stats = await (async () => {
          let data = await (this.$store.dispatch('campaigns/fetchStats', {
            id: this.campaign.id,
            force: true,
            byPassStorageCaching: true,
            startDate: this.start,
            endDate: this.end
          }))

          data = data.filter(crop => parseInt(crop.id) === parseInt(this.$router.currentRoute.params.crop_id))
          if (data.length !== 1) {
            return null
          }

          return data[0]
        })()
        this.progressions = await (async () => {
          let data = await (this.$store.dispatch('campaigns/fetchProgression', {
            id: this.campaign.id,
            force: true,
            unit: this.campaignUnit,
            byPassStorageCaching: true,
            startDate: this.start,
            endDate: this.end
          }))

          data = data.filter(crop => parseInt(crop.id) === parseInt(this.$router.currentRoute.params.crop_id))
          if (data.length !== 1) {
            return null
          }

          return data[0]

        })()

        this.dataLoader = false
      } catch (e) {
        setTimeout(() => {
          this.error = true
        }, 100)
      }
    },
    /**
     * @param {{data:String,name:String,start:String,end:String}} item
     */
    async goToYear(item) {
      await this.$router.push({
        name: NAME_RESUME_ONE_CAMPAIGN_CROP_CUSTOM_SCOPE,
        params: {
          campaign_id: this.campaign.id,
          crop_id: this.$router.currentRoute.params.crop_id,
          start: encodeURI(item.start),
          end: encodeURI(item.end),
        },
      })
    },
    /**
     * @param {{data:String,name:String,start:String,end:String}} item
     */
    async goToMonth(item) {
      await this.$router.push({
        name: NAME_RESUME_ONE_CAMPAIGN_CROP_CUSTOM_SCOPE,
        params: {
          campaign_id: this.campaign.id,
          crop_id: this.$router.currentRoute.params.crop_id,
          start: encodeURI(item.start),
          end: encodeURI(item.end),
        },
      })
    },
    /**
     * @param {Object} unit
     */
    goToDay(unit) {
      this.$router.push({
        name: NAME_HISTORY_DATE,
        params: {
          date: unit.data,
        },
      })
    },
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
